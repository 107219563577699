.footer-containerDevFtr {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #070707;
  color: #fff;
  flex-wrap: wrap;
  
}

.footer-columnDevFtr {
  flex: 1;
  min-width: 250px;
  margin: 10px;
  box-sizing: border-box;
}

.footer-logoDevFtr {
  max-height: 80px;
  margin-bottom: -10px;
  border-radius: 5px;
  width: 80px;
}

.footer-logoDevFtr12 {
  max-height: 120px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 160px;
  margin-top: -20px;
}

.footer-textDevFtr {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
  max-width: 280px;
}

.footer-headingDevFtr {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #fff;
}

.options25 {
  color: #fff;
}

.footer-services-listDevFtr,
.footer-tech-listDevFtr {
  list-style: none;
  padding: 0;
  font-size: 16px;
}

.footer-services-listDevFtr li,
.footer-tech-listDevFtr li {
  margin-bottom: 12px;
}

.footer-addressDevFtr {
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 5px;
  
  max-width: 350px;
  word-wrap: break-word;
}

.footer-emailDevFtr,
.footer-callDevFtr {
  font-size: 16px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.footer-location-iconDevFtr,
.footer-message-iconDevFtr,
.footer-call-iconDevFtr {
  margin-right: 8px;
  font-size: 18px;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .footer-containerDevFtr {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 278px;
  }

  .footer-columnDevFtr {
    width: 10%;
    margin-bottom: 15px;
  }

  .footer-logoDevFtr,
  .footer-logoDevFtr12 {
    width: auto;
    max-height: 100px;
    margin-bottom: 10px;
  }

  .footer-headingDevFtr {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .footer-textDevFtr,
  .footer-services-listDevFtr li,
  .footer-tech-listDevFtr li,
  .footer-addressDevFtr,
  .footer-emailDevFtr {
    font-size: 14px;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
