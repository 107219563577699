/* General Container Styling */
.contactus-container {
  max-width: 850px; /* Reduced width for desktop */
  margin: 0 auto;
  padding: 20px;
}

/* Content Styling */
.contactus-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Adjusts spacing dynamically */
}

/* Image Styling */
.contactus-img {
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactus-logo {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

/* Details Styling */
.contactus-details {
  flex: 1;
  padding: 10px;
  text-align: left;
}

/* Headings */
.contactus-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.contactus-subheading {
  font-size: 1.5rem;
  color: red;
  margin-bottom: 10px;
}

/* Address & Email */
.contactus-address,
.contactus-email,
.contactus-phone {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

.contactus-email-link {
  color: #007bff;
  text-decoration: none;
}

.contactus-email-link:hover {
  text-decoration: underline;
}

/* Phone Styling */
.contactus-phone-number {
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contactus-container {
      max-width: 700px; /* Adjust width for tablets */
  }

  .contactus-content {
      flex-direction: column;
      text-align: center;
  }

  .contactus-img {
      width: 80%;
      padding: 5px;
  }

  .contactus-details {
      padding: 15px;
  }

  .contactus-heading {
      font-size: 1.8rem;
  }

  .contactus-subheading {
      font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .contactus-container {
      max-width: 250px; /* Reduced width for mobile */
      padding: 10px;
  }

  .contactus-logo {
      width: 100%;
  }

  .contactus-details {
      text-align: center;
  }

  .contactus-heading {
      font-size: 1.6rem;
  }

  .contactus-subheading {
      font-size: 1.2rem;
  }

  .contactus-address,
  .contactus-email,
  .contactus-phone {
      font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .contactus-container {
      max-width: 250px; /* Even smaller width for very small screens */
      padding: 5px;
  }

  .contactus-heading {
      font-size: 1.4rem;
  }

  .contactus-subheading {
      font-size: 1rem;
  }

  .contactus-address,
  .contactus-email,
  .contactus-phone {
      font-size: 0.8rem;
  }
}
